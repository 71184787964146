import { PropsWithChildren, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

export function NoteDetails({ children }: PropsWithChildren) {
  const [collapsed, setCollapsed] = useState(true);

  const handleClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box
      sx={{
        mt: 2.5,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        onClick={handleClick}
        sx={{
          mb: collapsed ? 2 : 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InfoOutlined sx={{ color: 'info.main' }} />
          <Typography
            sx={{
              color: 'info.main',
              ml: 1,
              mr: 1.5,
              textTransform: 'uppercase',
            }}
          >
            {'note details'}
          </Typography>
          {collapsed ? (
            <ArrowDropDown sx={{ color: 'info.main' }} />
          ) : (
            <ArrowDropUp sx={{ color: 'info.main' }} />
          )}
        </Box>
      </Box>
      {collapsed ? null : children}
    </Box>
  );
}
