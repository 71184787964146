import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { DaysOfWeek } from '@guider-global/shared-types';
import { BaseLoadingButton } from '@guider-global/ui';
import { Box } from '@mui/material';
import { useLocalization } from 'hooks';
import { Availability } from './components';
import { useAvailabilityForm } from './hooks';
import { SettingsAvailabilitiesFields } from './types';

export * from './types';

export function AvailabilityForm() {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const availability = baseLanguage?.settings?.availability;
  const weekdays = availability?.weekdays;
  const saveChangesButtonLabel =
    baseLanguage?.globals?.common?.save_changes_button_label;

  const {
    control,
    errors,
    setValue,
    handleSubmit,
    onSubmit,
    onError,
    isSubmitting,
    isDirty,
    isValid,
    hasSetAvailability,
  } = useAvailabilityForm();
  const isNotDirty = !isDirty;
  const isInvalid = !isValid;

  const availabilityProps = DaysOfWeek.map((dayOfTheWeek) => {
    const weekday = weekdays && weekdays[dayOfTheWeek];
    return {
      dayLabel: weekday,
      dayEnabledName:
        `${dayOfTheWeek}Enabled` as keyof SettingsAvailabilitiesFields,
      dayStartName:
        `${dayOfTheWeek}Start` as keyof SettingsAvailabilitiesFields,
      dayEndName: `${dayOfTheWeek}End` as keyof SettingsAvailabilitiesFields,
    };
  });
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit, onError)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
          mt: 0,
        },
      }}
    >
      <Box
        sx={{
          mt: 3,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {availabilityProps.map((props) => (
          <Availability
            key={props.dayLabel}
            {...props}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        ))}
      </Box>
      <BaseLoadingButton
        data-cy="settings_availability-button"
        type="submit"
        disabled={hasSetAvailability ? isNotDirty || isInvalid : false}
        loading={isSubmitting}
        sx={{
          alignSelf: { xs: 'stretch', md: 'flex-end' },
        }}
      >
        {saveChangesButtonLabel}
      </BaseLoadingButton>
    </Box>
  );
}
