import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import Edit from '@mui/icons-material/Edit';
import { Box, Typography, useTheme } from '@mui/material';
import { useLocalization } from 'hooks';

export function NoteStatusUnsaved() {
  const theme = useTheme();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        mt: 2,
        [theme.breakpoints.up('md')]: {
          justifyContent: 'flex-end',
        },
      }}
      data-cy="relationship-notes-modal-note-form-status-unsaved"
    >
      <Typography
        color="text.secondary"
        variant="subtitle2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
        }}
      >
        <Edit
          fontSize="small"
          sx={{ mr: 1, color: theme.palette.text.disabled }}
        />
        {
          baseLanguage?.relationships?.notes?.notes_modal
            ?.notes_modal_create_edit_note?.note_status
            ?.note_status_unsaved_label
        }
      </Typography>
    </Box>
  );
}
