import CalendarToday from '@mui/icons-material/CalendarToday';
import Edit from '@mui/icons-material/Edit';
import { Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { INotesCreateFormProps } from '../..';

export interface NoteStatusSavedProps
  extends Pick<INotesCreateFormProps, 'lastUpdatedBy' | 'createdBy'> {
  isLoading?: boolean;
}
export function NoteStatusSaved({
  lastUpdatedBy,
  createdBy,
  isLoading,
}: NoteStatusSavedProps) {
  const theme = useTheme();

  return (
    <Grid
      container
      mt={2}
      data-cy="relationship-notes-modal-note-form-status-saved"
    >
      <Grid item xs={12} md={6}>
        <Typography
          color="text.secondary"
          variant="subtitle2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            [theme.breakpoints.down('md')]: {
              mb: 2,
            },
            [theme.breakpoints.up('md')]: {
              mr: 2,
            },
          }}
        >
          <Edit
            fontSize="small"
            sx={{ mr: 1, color: theme.palette.text.disabled }}
          />
          {isLoading && <Skeleton variant="text" width={140} />}
          {!isLoading && lastUpdatedBy}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          color="text.secondary"
          variant="subtitle2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
          }}
        >
          <CalendarToday
            fontSize="small"
            sx={{ mr: 1, color: theme.palette.text.disabled }}
          />
          {isLoading && <Skeleton variant="text" width={140} />}
          {!isLoading && createdBy}
        </Typography>
      </Grid>
    </Grid>
  );
}
