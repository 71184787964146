import { FormTextField } from 'components';
import { useFormContext } from 'react-hook-form';
import { INoteInputs, NoteFormField } from '../..';

interface NoteTitleProps {
  label: string;
  disabled?: boolean;
}

export function NoteTitle({ label, disabled }: NoteTitleProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<INoteInputs>();

  return (
    <FormTextField
      name={NoteFormField.Title}
      control={control}
      label={label}
      errors={errors}
      disabled={disabled}
      required
    />
  );
}
