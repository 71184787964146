import { useEffect } from 'react';
import { Box } from '@mui/material';
import {
  Control,
  FieldErrorsImpl,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { AvailabilityStatus } from '../AvailabilityStatus';
import { AvailabilityInterval } from '../AvailabilityInterval';
import { SettingsAvailabilitiesFields } from 'forms/AvailabilityForm/types';

interface AvailabilityProps {
  dayLabel: string | undefined;
  dayEnabledName: keyof SettingsAvailabilitiesFields;
  dayStartName: keyof SettingsAvailabilitiesFields;
  dayEndName: keyof SettingsAvailabilitiesFields;
  control: Control<SettingsAvailabilitiesFields>;
  errors: FieldErrorsImpl<SettingsAvailabilitiesFields>;
  setValue: UseFormSetValue<any>;
}

export function Availability({
  dayLabel,
  dayEnabledName,
  dayStartName,
  dayEndName,
  control,
  errors,
  setValue,
}: Readonly<AvailabilityProps>) {
  const [dayEnabled, dayStart, dayEnd] = useWatch({
    control,
    name: [dayEnabledName, dayStartName, dayEndName],
  });

  useEffect(() => {
    if (!dayEnabled) {
      setValue(dayStartName, '');
      setValue(dayEndName, '');
    }
  }, [dayEnabled, dayStartName, dayEndName, setValue]);

  useEffect(() => {
    if (
      typeof dayStart === 'string' &&
      typeof dayEnd === 'string' &&
      dayStart >= dayEnd
    ) {
      setValue(dayEndName, '');
    }
  }, [dayStart, dayEnd, dayEndName, setValue]);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        [theme.breakpoints.up('md')]: {
          height: '59px',
          flexDirection: 'row',
          alignItems: 'center',
        },
      })}
    >
      <AvailabilityStatus
        label={dayLabel}
        name={dayEnabledName}
        control={control}
        errors={errors}
      />
      <AvailabilityInterval
        fromTimeName={dayStartName}
        toTimeName={dayEndName}
        enabled={dayEnabled as boolean | null}
        control={control}
        errors={errors}
      />
    </Box>
  );
}
