import { FieldErrorsImpl, SubmitErrorHandler, useForm } from 'react-hook-form';
import {
  DayOfWeekAvailabilities,
  DaysOfWeek,
} from '@guider-global/shared-types';
import { useAvailabilities } from 'hooks';
import moment from 'moment-timezone';
import { datadogLogs } from '@guider-global/datadog';
import { SettingsAvailabilitiesFields } from 'forms/AvailabilityForm/types';
import { useSettings } from '@guider-global/front-end-hooks';

export function useAvailabilityForm() {
  const {
    defaultAvailability,
    availabilities,
    createAvailabilities,
    updateAvailabilities,
  } = useAvailabilities({});
  const availability = availabilities?.at(0);

  const { settings } = useSettings({});
  const setting = settings?.at(0);

  const formValues = availability
    ? DaysOfWeek.reduce((acc, day) => {
        acc[`${day}Enabled`] = availability[`${day}Enabled`];
        acc[`${day}Start`] = availability[`${day}Start`];
        acc[`${day}End`] = availability[`${day}End`];

        return acc;
      }, {} as DayOfWeekAvailabilities)
    : defaultAvailability;

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<SettingsAvailabilitiesFields>({
    mode: 'onChange',
    defaultValues: defaultAvailability,
    values: formValues,
  });

  const onSubmit = async () => {
    const timezone = moment.tz.guess(true);
    const data = { ...getValues(), timezone: setting?.timezone ?? timezone };

    if (!availability) {
      await createAvailabilities(data);
    } else {
      await updateAvailabilities(availability.id, data);
    }
  };

  const onError: SubmitErrorHandler<SettingsAvailabilitiesFields> = (
    errors: FieldErrorsImpl<SettingsAvailabilitiesFields>,
  ) => {
    datadogLogs.logger.error('AvailabilityForm:onSubmit', { errors });
    console.error(errors);
  };

  return {
    control,
    errors,
    setValue,
    handleSubmit,
    onSubmit,
    onError,
    isSubmitting,
    isDirty,
    isValid,
    hasSetAvailability: !!availability,
  };
}
