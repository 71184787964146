import { useCustomFields, useProfiles } from '@guider-global/front-end-hooks';
import { getCountryList, getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import {
  ICustomField,
  IProfile,
  SanityProfileField,
} from '@guider-global/shared-types';
import {
  Button,
  DateFieldValue,
  DropdownItemProps,
  FormDateField,
  FormMultiSelectInput,
  FormPhoneNumber,
  FormPhoneNumberValue,
  FormSelectInput,
  FormTextArea,
  MultiSelectAreaInputValue,
  SelectareaInputValue,
  Stack,
  TextareaInputValue,
} from '@guider-global/ui';
import { Box } from '@mui/system';
import { interpolate } from 'functions';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAppAlert } from 'store/slices/appSlice';

type ProfileFormValues = {
  [key: string]:
    | FormPhoneNumberValue
    | TextareaInputValue
    | SelectareaInputValue
    | MultiSelectAreaInputValue
    | { fieldType: 'check'; value: string; name?: string }
    | DateFieldValue;
};

interface EditProfileFormProps {
  profile: IProfile | undefined;
  localeCode: string;
}
export function EditProfileForm({ profile, localeCode }: EditProfileFormProps) {
  // State and Refs
  const [loading, setLoading] = useState<boolean>(false);

  // Hooks
  const dispatch = useDispatch();

  // Organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug,
    localeCode,
  });

  const personalDetails = organization?.personal_details;
  const organizationFields = organization?.profile_fields ?? [];

  // Profiles
  const { reqProfiles, isLoadingProfiles } = useProfiles({});

  // Base Language
  const { baseLanguage } = useBaseLanguage({
    localeCode,
  });

  const profileFieldText = baseLanguage?.profile_fields;
  const globalRequiredError = baseLanguage?.globals?.errors?.required_field;

  const genders: DropdownItemProps[] = Object.entries(
    profileFieldText?.gender?.options ?? {},
  ).map(([key, label]) => {
    return {
      key,
      value: key,
      label,
    };
  });

  // Custom fields
  const { reqCustomFields } = useCustomFields({});

  // Form handling
  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
    reset,
  } = useForm<ProfileFormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (!profile || isLoadingProfiles || loading) return;

    const customFields = profile?.organizationFields ?? [];
    const fields = Object.assign(
      {},
      ...(customFields as ICustomField[]).map(
        ({ fieldType, value, fieldSlug }) => {
          if (fieldType === 'multi-select') {
            return {
              [fieldSlug]: {
                fieldType,
                value: value ?? [],
              },
            };
          } else {
            return {
              [fieldSlug]: {
                fieldType,
                value: value ?? '',
              },
            };
          }
        },
      ),
    );

    reset({
      ...fields,
      firstName: {
        fieldType: 'free-text',
        value: profile?.firstName ?? '',
      },
      lastName: {
        fieldType: 'free-text',
        value: profile?.lastName ?? '',
      },
      ...(personalDetails?.phone_number?.enabled && {
        phoneNumber: {
          fieldType: 'free-text',
          value: profile?.phoneNumber ?? '',
        },
      }),

      ...(personalDetails?.job_title?.enabled && {
        jobTitle: {
          fieldType: 'free-text',
          value: profile?.jobTitle ?? '',
        },
      }),

      ...(personalDetails?.linkedin_url?.enabled && {
        linkedInUrl: {
          fieldType: 'free-text',
          value: profile?.linkedInUrl ?? '',
        },
      }),

      ...(personalDetails?.town_or_city?.enabled && {
        townOrCity: {
          fieldType: 'free-text',
          value: profile?.townOrCity ?? '',
        },
      }),

      ...(personalDetails?.country?.enabled && {
        country: {
          fieldType: 'select',
          value: profile?.country ?? '',
        },
      }),

      ...(personalDetails?.pronouns?.enabled && {
        pronouns: {
          fieldType: 'select',
          value: profile?.pronouns ?? '',
        },
      }),
      ...(personalDetails?.gender?.enabled && {
        gender: {
          fieldType: 'select',
          value: profile?.gender ?? '',
        },
      }),
      ...(personalDetails?.date_of_birth?.enabled && {
        dateOfBirth: {
          fieldType: 'date',
          value: profile?.dateOfBirth ? new Date(profile?.dateOfBirth) : null,
        },
      }),
      bio: {
        fieldType: 'free-text',
        value: profile?.bio ?? '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const onSubmit: SubmitHandler<ProfileFormValues> = async (
    data: ProfileFormValues,
  ) => {
    if (!profile) return;
    setLoading(true);
    const {
      firstName,
      lastName,
      jobTitle,
      linkedInUrl,
      townOrCity,
      country,
      pronouns,
      gender,
      dateOfBirth,
      phoneNumber,
      bio,
      ...customFields
    } = data;

    let dateOfBirthString = '';
    if (dateOfBirth?.value) {
      dateOfBirthString =
        (dateOfBirth?.value as Date).toISOString().split('T')[0] ?? '';
    }

    const profileData = {
      country: country?.value as string,
      firstName: firstName?.value as string,
      phoneNumber: phoneNumber?.value as string,
      lastName: lastName?.value as string,
      jobTitle: jobTitle?.value as string,
      townOrCity: townOrCity?.value as string,
      pronouns: pronouns?.value as string,
      gender: gender?.value as string,
      displayName: firstName?.value + ' ' + lastName?.value,
      dateOfBirth: dateOfBirthString,
      linkedInUrl: linkedInUrl?.value as string,
      bio: bio.value as string,
    };

    const profilesResult = await reqProfiles({
      method: 'PATCH',
      url: `/profiles/${profile.id}`,
      data: profileData,
    });

    if (profilesResult?.status === 'success' && profilesResult?.data) {
      let customFieldsPostData: ICustomField[] = [];
      const existingFields = profile.organizationFields as ICustomField[];

      const updatedFieldsData = Object.values(customFields)
        .filter((field) => field.name)
        .map(({ fieldType, value, name }) => {
          return {
            fieldSlug: name,
            organizationSlug,
            fieldType,
            value,
            profileId: profile.id,
          };
        });

      updatedFieldsData.forEach(async (field) => {
        const profileField = existingFields.find(
          (f) => f.fieldSlug === field.fieldSlug,
        );

        if (profileField) {
          const fieldId = profileField?.id;
          const fieldType = profileField?.fieldType;
          let value;

          if (fieldType === 'check') return;

          if (field.fieldType === 'date') {
            value = field?.value?.toString();
          } else {
            value = field?.value as string | string[];
          }

          await reqCustomFields({
            method: 'PATCH',
            url: `/customfields/${fieldId}`,
            data: {
              value,
              fieldType,
            },
          });
        } else {
          customFieldsPostData.push(field as ICustomField);
        }
      });

      if (customFieldsPostData.length > 0) {
        const customFieldsResult = await reqCustomFields({
          method: 'POST',
          url: `/customfields`,
          data: [...customFieldsPostData],
        });

        if (customFieldsResult?.status === 'error') {
          dispatch(
            showAppAlert({
              severity: 'error',
              message: customFieldsResult.message,
              timeout: 2000,
            }),
          );
        }
      }

      await reqProfiles({
        method: 'GET',
        url: '/profiles',
      });
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: 'Error creating profile',
          timeout: 2000,
        }),
      );
    }
    setLoading(false);
    window.scrollTo(0, 0);
  };

  // Countries

  const countryList: DropdownItemProps[] = useMemo(
    () =>
      getCountryList({ countryCode: 'GB', languageCode: 'en' }).map(
        ({ key, countryName }) => ({
          key,
          label: countryName,
          value: key,
        }),
      ),
    [],
  );

  return (
    <>
      <Stack direction={'column'} justifyContent={'flex-start'} pt={4}>
        <Stack
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}
          direction={'column'}
          gap={1}
        >
          <FormTextArea
            control={control}
            rows={1}
            label={profileFieldText?.first_name_input_label + '*'}
            labelSize="xs"
            placeholder={profileFieldText?.first_name_input_label}
            name="firstName"
            errorMessage={globalRequiredError}
          />
          <FormTextArea
            control={control}
            rows={1}
            label={profileFieldText?.last_name_input_label + '*'}
            labelSize="xs"
            placeholder={profileFieldText?.last_name_input_label}
            name="lastName"
            errorMessage={globalRequiredError}
          />
          <FormDateField
            maxDate={new Date()}
            minDate={new Date('1900/01/01')}
            name="dateOfBirth"
            placeholder={
              personalDetails?.date_of_birth?.custom_label ??
              profileFieldText?.date_of_birth_input_label
            }
            label={
              personalDetails?.date_of_birth?.custom_label ??
              profileFieldText?.date_of_birth_input_label +
                (personalDetails?.date_of_birth?.required ? '*' : '')
            }
            labelSize="xs"
            control={control}
            hidden={!personalDetails?.date_of_birth?.enabled}
            required={personalDetails?.date_of_birth?.required}
            validationErrorMessage={interpolate(
              baseLanguage?.globals?.errors?.validation_error_blank,
              {
                fieldName:
                  personalDetails?.date_of_birth?.custom_label ??
                  profileFieldText?.date_of_birth_input_label,
              },
            )}
            requiredErrorMessage={baseLanguage?.globals?.errors?.required_field}
          />
          <FormSelectInput
            hidden={!personalDetails?.gender?.enabled}
            required={personalDetails?.gender?.required}
            control={control}
            label={
              personalDetails?.gender?.custom_label ??
              profileFieldText?.gender?.gender_input_label +
                (personalDetails?.gender?.required ? '*' : '')
            }
            name="gender"
            options={genders}
            placeholder={
              personalDetails?.gender?.custom_label ??
              profileFieldText?.gender?.gender_input_label
            }
            labelSize="xs"
            errorMessage={baseLanguage?.globals?.errors?.required_field}
          />
          <FormTextArea
            hidden={!personalDetails?.pronouns?.enabled}
            required={personalDetails?.pronouns?.required}
            control={control}
            rows={1}
            label={
              personalDetails?.pronouns?.custom_label ??
              profileFieldText?.pronouns_input_label +
                (personalDetails?.pronouns?.required ? '*' : '')
            }
            labelSize="xs"
            placeholder={
              personalDetails?.pronouns?.custom_label ??
              profileFieldText?.pronouns_input_label
            }
            name="pronouns"
            errorMessage={baseLanguage?.globals?.errors?.required_field}
          />

          <FormTextArea
            hidden={!personalDetails?.job_title?.enabled}
            required={personalDetails?.job_title?.required}
            control={control}
            rows={1}
            label={
              personalDetails?.job_title?.custom_label ??
              profileFieldText?.job_title_input_label +
                (personalDetails?.job_title?.required ? '*' : '')
            }
            labelSize="xs"
            placeholder={
              personalDetails?.job_title?.custom_label ??
              profileFieldText?.job_title_input_label
            }
            name="jobTitle"
            errorMessage={baseLanguage?.globals?.errors?.required_field}
          />
          <FormTextArea
            hidden={!personalDetails?.linkedin_url?.enabled}
            required={personalDetails?.linkedin_url?.required}
            control={control}
            rows={1}
            label={
              personalDetails?.linkedin_url?.custom_label ??
              profileFieldText?.linkedin_url?.input_label +
                (personalDetails?.linkedin_url?.required ? '*' : '')
            }
            labelSize="xs"
            placeholder={
              personalDetails?.linkedin_url?.custom_label ??
              profileFieldText?.linkedin_url?.input_label
            }
            name="linkedInUrl"
            errorMessage={baseLanguage?.globals?.errors?.required_field}
            type="url"
            validationErrorMessage={interpolate(
              baseLanguage?.globals?.errors?.validation_error_blank,
              {
                fieldName:
                  personalDetails?.linkedin_url?.custom_label ??
                  profileFieldText?.linkedin_url?.input_label,
              },
            )}
          />
          <FormPhoneNumber
            control={control}
            name="phoneNumber"
            label={
              profileFieldText?.phone_number_input_label +
              (personalDetails?.phone_number?.required ? '*' : '')
            }
            placeholder={profileFieldText?.phone_number_input_label}
            validationErrorMessage={interpolate(
              baseLanguage?.globals?.errors?.validation_error_blank,
              {
                fieldName: profileFieldText?.phone_number_input_label ?? '',
              },
            )}
            hidden={!personalDetails?.phone_number?.enabled}
            required={personalDetails?.phone_number?.required}
            initialCountry={'gb'}
          />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={0}
            gap={3}
            display={
              !personalDetails?.town_or_city?.enabled &&
              !personalDetails?.country?.enabled
                ? 'none'
                : ''
            }
          >
            <Box
              display={personalDetails?.town_or_city?.enabled ? '' : 'none'}
              width={'100%'}
            >
              <FormTextArea
                hidden={!personalDetails?.town_or_city?.enabled}
                required={personalDetails?.town_or_city?.required}
                control={control}
                rows={1}
                label={
                  personalDetails?.town_or_city?.custom_label ??
                  profileFieldText?.town_or_city_input_label +
                    (personalDetails?.town_or_city?.required ? '*' : '')
                }
                labelSize="xs"
                placeholder={
                  personalDetails?.town_or_city?.custom_label ??
                  profileFieldText?.town_or_city_input_label
                }
                name="townOrCity"
                errorMessage={baseLanguage?.globals?.errors?.required_field}
              />
            </Box>
            <Box
              display={personalDetails?.country?.enabled ? '' : 'none'}
              width={'100%'}
            >
              <FormSelectInput
                hidden={!personalDetails?.country?.enabled}
                required={personalDetails?.country?.required}
                control={control}
                label={
                  personalDetails?.country?.custom_label ??
                  profileFieldText?.country?.country_input_label +
                    (personalDetails?.country?.required ? '*' : '')
                }
                name="country"
                options={countryList}
                placeholder={
                  personalDetails?.country?.custom_label ??
                  profileFieldText?.country?.country_input_label
                }
                labelSize="xs"
                errorMessage={baseLanguage?.globals?.errors?.required_field}
              />
            </Box>
          </Stack>

          {organizationFields.map((field: SanityProfileField) => {
            const fieldOptions: DropdownItemProps[] =
              field?.options?.map((option: any) => {
                return {
                  key: option.id.current,
                  value: option.id.current,
                  label: option.label,
                };
              }) ?? [];

            if (field?.hidden) return undefined;

            if (field?.type === 'free-text') {
              return (
                <FormTextArea
                  tooltipText={field?.text?.assistive_text}
                  required={field?.required}
                  key={field?.id?.current}
                  control={control}
                  rows={
                    field?.free_text_config?.text_area_size === 'singleline'
                      ? 1
                      : 7
                  }
                  label={field?.text?.label + (field?.required ? '*' : '')}
                  labelSize="xs"
                  placeholder={field?.text?.label}
                  name={field?.id?.current}
                  errorMessage={baseLanguage?.globals?.errors?.required_field}
                  min={field?.free_text_config?.minimum_length}
                  max={field?.free_text_config?.maximum_length}
                  minErrorText={
                    field?.free_text_config?.text
                      ?.minimum_length_not_met_validation_error
                  }
                  maxErrorText={
                    field?.free_text_config?.text
                      ?.maximum_length_exceeded_validation_error
                  }
                />
              );
            }
            if (field.type === 'select') {
              return (
                <FormSelectInput
                  tooltipText={field?.text?.assistive_text}
                  required={field?.required}
                  key={field?.id?.current}
                  control={control}
                  label={field?.text?.label + (field?.required ? '*' : '')}
                  name={field?.id?.current}
                  options={fieldOptions}
                  placeholder={field?.text?.label}
                  labelSize="xs"
                  errorMessage={baseLanguage?.globals?.errors?.required_field}
                />
              );
            }

            if (field?.type === 'multi-select') {
              return (
                <FormMultiSelectInput
                  tooltipText={field?.text?.assistive_text}
                  required={field?.required}
                  key={field?.id?.current}
                  control={control}
                  label={field?.text?.label + (field?.required ? '*' : '')}
                  name={field?.id?.current}
                  options={fieldOptions}
                  placeholder={field?.text?.label}
                  labelSize="xs"
                  errorMessage={baseLanguage?.globals?.errors?.required_field}
                  min={field?.multi_select_config?.minimum_selections}
                  max={field?.multi_select_config?.maximum_selections}
                  minErrorMessage={
                    field?.free_text_config?.text
                      ?.minimum_length_not_met_validation_error
                  }
                  maxErrorMessage={
                    field?.free_text_config?.text
                      ?.maximum_length_exceeded_validation_error
                  }
                />
              );
            }
            return <></>;
          })}
          {organization?.personal_details?.enable_bio === true && (
            <FormTextArea
              control={control}
              rows={10}
              label={baseLanguage?.globals?.common?.bio_label}
              labelSize="xs"
              name="bio"
              required={false}
            />
          )}
          <Box>
            <Button
              loading={loading || isLoadingProfiles}
              variant="contained"
              label={baseLanguage?.globals?.common?.save_changes_button_label}
              color="info"
              type="submit"
              disabled={!isValid || !isDirty}
            />
          </Box>
        </Stack>
      </Stack>
    </>
  );
}
