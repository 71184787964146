import { SelectOptions } from 'components/FormDropdownTyped/types';

export function getTimes(step = 15): SelectOptions {
  const times: SelectOptions = [];

  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += step) {
      const hour = h.toString().padStart(2, '0');
      const minute = m.toString().padStart(2, '0');
      const time = `${hour}:${minute}`;
      times.push({ label: time, value: time });
    }
  }

  return times;
}
