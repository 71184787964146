// external
import { Box, SxProps, Theme } from '@mui/material';
import React, { useMemo } from 'react';

// internal

// components

// hooks
import { INote, SlateDescendant } from '@guider-global/shared-types';
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { useLocation } from 'react-router-dom';

import { useUsers } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import {
  NoteCollaborate,
  NoteContent,
  NoteDetails,
  NoteStatusSaved,
  NoteStatusUnsaved,
  NoteTitle,
} from './components';

//types
export enum NoteFormField {
  Title = 'title',
  Content = 'content',
  IsPublic = 'isPublic',
}

export interface INoteInputs {
  [NoteFormField.Title]: string | undefined;
  [NoteFormField.Content]: SlateDescendant[] | undefined;
  [NoteFormField.IsPublic]: boolean | undefined;
}

export interface INotesCreateFormProps {
  handleSubmit: () => void;
  lastUpdatedBy?: string;
  createdBy?: string;
  note?: Partial<INote>;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
}

export const NotesCreateForm: React.FC<INotesCreateFormProps> = ({
  handleSubmit,
  lastUpdatedBy,
  createdBy,
  note,
  sx = {},
  isLoading,
}) => {
  const isMobile = useMobileMediaQuery();

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const { users } = useUsers({});
  const user = users?.at(0);
  const isNoteOwner = note?.ownerId === user?.id;
  const isNotePrivate = note?.isPrivate;

  const { pathname } = useLocation();

  const isNoteCreation = useMemo(() => {
    return pathname.includes('/create');
  }, [pathname]);

  const isDisabled = isNotePrivate && !isNoteOwner;

  return (
    <Box
      id={note?.id}
      key={note?.id}
      component="form"
      onSubmit={handleSubmit}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      data-cy="relationship-notes-modal-note-form"
    >
      {!isMobile && isNoteCreation && <NoteStatusUnsaved />}
      {!isMobile && !isNoteCreation && (
        <NoteStatusSaved
          lastUpdatedBy={lastUpdatedBy}
          createdBy={createdBy}
          isLoading={isLoading}
        />
      )}
      <NoteTitle
        label={
          baseLanguage?.relationships?.notes?.notes_modal
            ?.notes_modal_create_edit_note?.note_title_input_label ?? ''
        }
        disabled={isDisabled}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          mt: 2.5,
        }}
      >
        <NoteContent initialValue={note?.content} disabled={isDisabled} />
      </Box>

      {isMobile ? (
        <NoteDetails>
          {isNoteCreation && <NoteStatusUnsaved />}
          {!isNoteCreation && (
            <NoteStatusSaved
              lastUpdatedBy={lastUpdatedBy}
              createdBy={createdBy}
              isLoading={isLoading}
            />
          )}
          <NoteCollaborate disabled={isNoteCreation ? false : !isNoteOwner} />
        </NoteDetails>
      ) : (
        <NoteCollaborate disabled={isNoteCreation ? false : !isNoteOwner} />
      )}
    </Box>
  );
};
