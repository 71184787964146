import { FormRichText } from 'components';
import { useFormContext } from 'react-hook-form';
import { Descendant } from 'slate';
import { INoteInputs, NoteFormField } from '../..';

interface NoteContentProps {
  initialValue: Descendant[] | undefined;
  disabled?: boolean;
}

export function NoteContent({ initialValue, disabled }: NoteContentProps) {
  const { control } = useFormContext<INoteInputs>();

  return (
    <FormRichText
      name={NoteFormField.Content}
      control={control}
      initialValue={initialValue}
      required
      sx={{
        wordBreak: 'break-word',
        minHeight: '100%',
      }}
      data-cy="relationship-notes-modal-note-form-content"
      disabled={disabled}
    />
  );
}
